import React, { useEffect, useState, Suspense, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useApiService } from "../Redux/Services/Api";
import { setUserData } from "../Redux/Reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import InvestmaneCalculator from "../Pages/PreLogin/InvestmaneCalculator/InvestmaneCalculator";
import PreLoginLayout from "../Components/Shared/UI/PreLoginLayout/PreLoginLayout";
import Sitemap from "../Utils/SiteMap";
import HealthCheckStatus from "../Components/Utils/HealthCheck";
const Loader = React.lazy(() => import("../Components/Shared/UI/Loader/Loader"));
// const HomePage = React.lazy(() => import("../Pages/PreLogin/HomePage/HomePage"));
const Faq = React.lazy(() => import("../Pages/PreLogin/Faq/Faq"));
const AuthLayout = React.lazy(() => import("../Components/Layout/Auth/SignUp"));
const PrivacyPolicy = React.lazy(() => import("../Pages/PreLogin/PrivacyPolicy/PrivacyPolicy"));
const TermsConditions = React.lazy(() => import("../Pages/PreLogin/TermsCondition/TermsCondition"));
// const CreamPms = React.lazy(() => import("../Pages/PreLogin/CreamPms/CreamPms"));
const ContactUs = React.lazy(() => import("../Pages/PreLogin/ContactUs/ContactUs"));
// const FractionalOwnership = React.lazy(() => import("../Pages/PreLogin/FractionalOwnership/FractionalOwnership"));
const NonIndividualKyc = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/NonIndividualKyc/NonIndividualKyc"));
const IndividualKyc = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/IndividualKyc/IndividualKyc"));
const HufKyc = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/HufKyc/HufKyc"));
const SignAgreementScreen = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/PostKycStepCompletion/SignAgreementScreen"));
const SuccessKyc = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/PostKycStepCompletion/SuccessKyc"));
const KycError = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/KycError"));
const Kyc = React.lazy(() => import("../Pages/PostLogin/Kyc/Kyc"));
const PostloginLayout = React.lazy(() => import("../Components/Shared/UI/PostloginLayout/PostloginLayout"));
const Referrals = React.lazy(() => import("../Pages/PostLogin/Referrals/Referrals"));
const Opportunity = React.lazy(() => import("../Pages/PostLogin/Opportunity/Opportunity"));
const PropertyDetails = React.lazy(() => import("../Pages/PreLogin/PropertyDetails/PropertyDetails"));
const MyInvestmentsDetails = React.lazy(() => import("../Pages/PostLogin/MyInvestmentsDetails/MyInvestmentsDetails"));
const NewsRoom = React.lazy(() => import("../Pages/PreLogin/NewsRoom/NewsRoom"));
const PageNotFound = React.lazy(() => import("../Components/Shared/UI/ErrorPages/PageNotFound"));
const AddExistingProfile = React.lazy(() => import("../Pages/PostLogin/AddMoreProfiles/AddExistingProfile/AddExistingProfile"));
const AddNewProfile = React.lazy(() => import("../Pages/PostLogin/AddMoreProfiles/AddNewProfile/AddNewProfile"));
const AddAccount = React.lazy(() => import("../Pages/PostLogin/AddMoreProfiles/AddAccount/AddAccount"));
const Sdi = React.lazy(() => import("../Pages/PreLogin/Sdi/Sdi"));
const AboutUs = React.lazy(() => import("../Pages/PreLogin/AboutUs/AboutUs"));
const CorporateBonds = React.lazy(() => import("../Pages/PreLogin/CorporateBonds/CorporateBonds"));
const PortfolioOverview = React.lazy(() => import("../Pages/PostLogin/PortfolioOverview/PortfolioOverview"));
const MyInvestments = React.lazy(() => import("../Pages/PostLogin/MyInvestments/MyInvestments"));
const Transactions = React.lazy(() => import("../Pages/PostLogin/Transactions/Transactions"));
const UserProfile = React.lazy(() => import("../Pages/PostLogin/UserProfile/UserProfile"));
const ManageAccount = React.lazy(() => import("../Pages/PostLogin/ManageAccount/ManageAccount"));
const AccountPreferences = React.lazy(() => import("../Pages/PostLogin/AccountPreferences/AccountPreferences"));
const ManualAadhaar = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/NonIndividualKyc/SubSteps/AuthorisedSignatoryDetails/DigilockerVerification/ManualStep/ManualAadhaar"));
const ManualPan = React.lazy(() => import("../Components/Layout/PostLogin/Kyc/NonIndividualKyc/SubSteps/AuthorisedSignatoryDetails/DigilockerVerification/ManualStep/ManualPan"));
const MicroLanding = React.lazy(() => import("../Pages/MicroLanding/MicroLanding"));


function WiseXRoute(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [isLoggedIn, setIsLoggedIn] = useState(Object.keys(userData).length !== 0);
  const { postData } = useApiService();

  useEffect(() => {
    setIsLoggedIn(Object.keys(userData).length !== 0);
  }, [userData]);

  const refresh = async () => {
    try {
      const response = await postData(process.env.REACT_APP_REFRESH_TOKEN, {}, false);
      if (response?.success && response?.data) {
        dispatch(setUserData(response.data));
      }
    } catch (e) {
      console.log("Error while refreshing:", e);
      dispatch(setUserData({}));
    }
  };

  useEffect(() => {
    if (
      (Object.keys(userData).length !== 0 || localStorage?.getItem("userData")) &&
      (Object.keys(userData).length === 0 || !localStorage?.getItem("userData"))
    ) {
      refresh();
    }
  }, [userData]);




  const memoizedPublicRoutes = useMemo(() => [
    { path: "/", element: <Navigate to={isLoggedIn ? "/dashboard/portfolio-overview" : "/signin"} replace /> },
    // { path: "/", element: isLoggedIn ? (<Navigate to="/dashboard/portfolio-overview" />) : (<Navigate to="/signin" />) },
    { path: "/faq", element: <PreLoginLayout component={<Faq />} /> },
    { path: "/privacy-policy", element: <PreLoginLayout component={<PrivacyPolicy />} /> },
    { path: "/terms-and-conditions", element: <PreLoginLayout component={<TermsConditions />} /> },
    { path: "/contact-us", element: <PreLoginLayout component={<ContactUs />} /> },
    { path: "/about-us", element: <PreLoginLayout component={<AboutUs />} /> },
    // { path: "/products/cream-pms", element: <PreLoginLayout component={<CreamPms />} /> },
    { path: "/products/sdi", element: <PreLoginLayout component={<Sdi />} /> },
    { path: "/products/corporate-bonds", element: <PreLoginLayout component={<CorporateBonds />} /> },
    { path: "/news-room", element: <PreLoginLayout component={<NewsRoom />} /> },
    { path: "/signin", element: <AuthLayout /> },
    { path: "/signup", element: <AuthLayout /> },
    { path: "/health", element: <HealthCheckStatus /> },
  ], []);

  const memoizedAuthRoutes = useMemo(() => [
    { path: "/dashboard/portfolio-overview", element: <PostloginLayout component={<PortfolioOverview />} /> },
    { path: "/property-details/:cardType/:id/:property-name", element: <PreLoginLayout component={<PropertyDetails />} /> },
    { path: "/kyc", element: <PreLoginLayout component={<Kyc />} /> },
    { path: "/kyc/Individual", element: <PreLoginLayout footerType="postlogin" component={<IndividualKyc />} /> },
    { path: "/kyc/NRI", element: <PreLoginLayout footerType="postlogin" component={<IndividualKyc />} /> },
    { path: "/kyc/Private", element: <PreLoginLayout footerType="postlogin" component={<NonIndividualKyc />} /> },
    { path: "/kyc/Huf", element: <PreLoginLayout footerType="postlogin" component={<HufKyc />} /> },
    { path: "/kyc/LLP", element: <PreLoginLayout footerType="postlogin" component={<NonIndividualKyc />} /> },
    { path: "/kyc/Partnership", element: <PreLoginLayout footerType="postlogin" component={<NonIndividualKyc />} /> },
    { path: "/kyc/sign-agreement", element: <PreLoginLayout component={<SignAgreementScreen />} /> },
    { path: "/kyc/kyc-success", element: <PreLoginLayout footerType={"postlogin"} component={<SuccessKyc />} /> },
    { path: "/kyc/kyc-failure", element: <PreLoginLayout footerType={"postlogin"} component={<KycError />} /> },
    { path: "/kyc/:accountType/aadhaar-verification", element: <PreLoginLayout footerType="postlogin" component={<ManualAadhaar />} /> },
    { path: "/kyc/:accountType/pan-verification", element: <PreLoginLayout footerType="postlogin" component={<ManualPan />} /> },
    { path: "/dashboard", element: <Navigate to="/dashboard/portfolio-overview" /> },
    { path: "/dashboard/my-investments", element: <PostloginLayout component={<MyInvestments />} /> },
    { path: "/dashboard/my-investments-details/:id", element: <PostloginLayout component={<MyInvestmentsDetails />} /> },
    { path: "/dashboard/transactions", element: <PostloginLayout component={<Transactions />} /> },
    { path: "/dashboard/referral", element: <PostloginLayout component={<Referrals />} /> },
    { path: "/dashboard/settings", element: <Navigate to="/dashboard/settings/profile" /> },
    { path: "/dashboard/settings/profile", element: <PostloginLayout component={<UserProfile />} /> },
    { path: "/dashboard/settings/manage-accounts", element: <PostloginLayout component={<ManageAccount />} /> },
    { path: "/dashboard/settings/account-preferences", element: <PostloginLayout component={<AccountPreferences />} /> },
    { path: "/investment-opportunities", element: <PreLoginLayout component={<Opportunity />} /> },
    { path: "/add-account", element: <AddAccount /> },
    { path: "/add-account/existing", element: <AddExistingProfile /> },
    { path: "/add-account/new", element: <AddNewProfile /> },
  ], []);


  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {/* <ScrollToTop />*/}
        <Routes>
          {memoizedPublicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {isLoggedIn &&
            memoizedAuthRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          <Route path="*" element={<PreLoginLayout component={<PageNotFound />} />} />
          <Route path="/sitemap.xml" element={<Sitemap />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default WiseXRoute;